import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flowKeys } from '@/app/queries/query-keys/flows';
import { useFlashMessage } from '@/app/flash-messages';
import {
	updateNextBestActionsAiOutput,
	UpdateAiOutputParams,
	updateNextBestActionsTasks,
	UpdateNextBestActionsTasksParams,
} from '@/app/api/flows';
import { queryKeys } from '@/app/queries';

export const useNextBestActionsAiOutput = (clientId?: string | number) => {
	return useQuery({
		...flowKeys.nextBestActionsAiOutput(clientId!),
		enabled: Boolean(clientId),
	});
};

export const useUpdateNextBestActionsAiOutput = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateAiOutputParams) => {
			return updateNextBestActionsAiOutput({ clientId, payload });
		},
		onSuccess: () => {
			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({
				queryKey: queryKeys.flows.nextBestActionsAiOutput._def,
			});
		},
	});
};

export const useNextBestActionsTasks = (clientId?: string | number) => {
	return useQuery({
		...flowKeys.nextBestActionsTasks(clientId!),
		enabled: Boolean(clientId),
	});
};

export const useUpdateNextBestActionsTasks = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateNextBestActionsTasksParams) => {
			return updateNextBestActionsTasks({ clientId, payload });
		},
		onSuccess: () => {
			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({
				queryKey: queryKeys.flows.nextBestActionsTasks._def,
			});
		},
	});
};
