import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { SupervisorAccountRounded as ClientsIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { Breadcrumbs, BreadcrumbsItem } from '@/components/ui/breadcrumbs';
import { Sidebar, SidebarNavItemProps } from '@/components/ui/sidebar';
import styles from './advisor-page.styles';

interface AdvisorPageLayoutProps {
	breadcrumbs?: BreadcrumbsItem[];
	title: ReactNode | string;
	children: ReactNode;
}

const AdvisorPageLayout: FunctionComponent<AdvisorPageLayoutProps> = ({
	breadcrumbs,
	title,
	children,
}): ReactElement => {
	const sidebarItems: SidebarNavItemProps[] = [
		{ title: 'Clients', icon: <ClientsIcon />, path: '/clients' },
	];

	const heading =
		typeof title === 'string' ? (
			<Typography component="h1" variant="h4">
				{title}
			</Typography>
		) : (
			title
		);

	return (
		<>
			<Sidebar items={sidebarItems} />
			<Stack direction="column" gap="1rem" component="main" role="main" sx={styles.advisor_page}>
				<Stack direction="column" gap="1rem">
					{breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
					{heading}
				</Stack>
				{children}
			</Stack>
		</>
	);
};

export default AdvisorPageLayout;
