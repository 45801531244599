import {
	TextField as MuiTextField,
	TextFieldProps as MuiTextFieldProps,
	InputAdornment,
	styled,
} from '@mui/material';
import { SearchRounded } from '@mui/icons-material';

type SearchFieldProps = Omit<
	MuiTextFieldProps,
	'variant' | 'error' | 'helperText' | 'InputLabelProps'
>;

const SearchIcon = (
	<InputAdornment position="end">
		<SearchRounded />
	</InputAdornment>
);

const SearchInput = styled(MuiTextField)<SearchFieldProps>(() => ({
	width: '220px',
}));

const SearchField: React.FC<SearchFieldProps> = ({ size, ...rest }): React.ReactElement => (
	<SearchInput
		{...rest}
		size={size || 'small'}
		slotProps={{ input: { endAdornment: SearchIcon } }}
	/>
);

export default SearchField;
