import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UpdateAiOutputParams, updateRiskAssessmentAiOutput } from '@/app/api/flows';
import { useFlashMessage } from '@/app/flash-messages';
import { queryKeys } from '@/app/queries';
import { flowKeys } from '@/app/queries/query-keys/flows';

export const useRiskAssessmentQuestionsAnswers = (clientId?: string | number) => {
	return useQuery({
		...flowKeys.riskAssessmentAnswers(clientId!),
		enabled: Boolean(clientId),
	});
};

export const useRiskAssessmentAiOutput = (clientId: string | number) => {
	return useQuery({
		...flowKeys.riskAssessmentAiOutput(clientId),
		placeholderData: keepPreviousData,
	});
};

export const useUpdateRiskAssessmentAiOutput = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateAiOutputParams) => {
			return updateRiskAssessmentAiOutput({ clientId, payload });
		},
		onSuccess: () => {
			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({
				queryKey: queryKeys.flows.riskAssessmentAiOutput._def,
			});
		},
	});
};
