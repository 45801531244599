import { FunctionComponent, ReactElement } from 'react';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';

type SelectProps = {
	value: string | number;
	segment: string;
	item: string;
	index: number;
	options: string[];
	handleChange: (
		event: SelectChangeEvent<number | string>,
		segment: string,
		item: string,
		index: number
	) => void;
};

const NextBestActionsSelect: FunctionComponent<SelectProps> = ({
	value,
	segment,
	item,
	index,
	options,
	handleChange,
}): ReactElement => {
	return (
		<Select
			size="small"
			displayEmpty
			autoWidth
			labelId={`label-${segment}-${item}-${index}`}
			id={`${segment}-${item}-${index}`}
			value={value}
			onChange={(event) => handleChange(event, segment, item, index)}
		>
			<MenuItem value={-1} disabled>
				Choose Option
			</MenuItem>
			{options.map((n, i) => (
				<MenuItem key={`${segment}-${n}`} value={i}>
					{n}
				</MenuItem>
			))}
		</Select>
	);
};

export default NextBestActionsSelect;
