import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { Question } from '@/app/api/flows';
import { AccordionItem } from '@/components/ui/accordion';
import { TextItem } from '@/components/layouts/text-item';
import { toKebabCase } from '@/shared/utils';

const useTransformQuestion = (question: Question, isExpanded: boolean = false): AccordionItem => {
	const { tile, questionAndAnswers } = question;

	const id = toKebabCase(tile);
	const title = tile;
	const textItems: ReactNode[] = questionAndAnswers.map(({ question, answer }) => (
		<TextItem title={question} key={question}>
			<Typography variant="body1">{answer}</Typography>
		</TextItem>
	));

	const children: ReactNode = (
		<Stack direction="column" gap="0.5rem">
			{textItems}
		</Stack>
	);

	return { id, title, defaultExpanded: isExpanded, children };
};

export default useTransformQuestion;
