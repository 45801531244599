import { FunctionComponent, ReactElement, useMemo } from 'react';
import { Portfolio } from '@/app/api/portfolio-considerations';
import { Column, Pagination, Table } from '@/components/ui/table';
import { getPortfolioMandateScore } from '../portfolio-considerations.utils';
import { DEFAULT_PAGE_SIZE } from './portfolio-table.constants';

interface PortfoliosTableProps {
	data: Portfolio[];
	page: number;
	pageSize?: number;
	onPageChange: (page: number) => void;
}

const PortfoliosTable: FunctionComponent<PortfoliosTableProps> = ({
	data,
	page,
	pageSize = DEFAULT_PAGE_SIZE,
	onPageChange,
}): ReactElement => {
	const currentPageItems = useMemo(() => {
		const start = page * pageSize;
		const end = start + pageSize;
		return data.slice(start, end);
	}, [data, page, pageSize]);

	const columns: Column<Portfolio>[] = [
		{
			name: 'Name',
			accessors: ['name'],
		},
		{
			name: 'P Number',
			accessors: ['symbol'],
		},
		{
			name: 'Mandate Score',
			render: (portfolio) => getPortfolioMandateScore(portfolio),
		},
	];

	const pagination: Pagination = {
		count: data?.length || 0,
		page,
		rowsPerPage: pageSize,
		onPageChange,
	};

	return <Table<Portfolio> columns={columns} rows={currentPageItems} pagination={pagination} />;
};

export default PortfoliosTable;
