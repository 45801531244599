import { SxProps } from '@mui/material';

const LOADER_LEFT: SxProps = {
	marginRight: '0.625rem',
};

const LOADER_RIGHT: SxProps = {
	marginLeft: '0.625rem',
};

export default {
	loader_left: LOADER_LEFT,
	loader_right: LOADER_RIGHT,
};
