import { FunctionComponent, ReactElement } from 'react';
import {
	Box,
	Divider,
	Paper,
	Stack,
	Typography,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';

const LifePlanPage: FunctionComponent = (): ReactElement => {
	return (
		<Box component={Paper} padding="1.5rem">
			<Stack direction="row" alignItems="center" justifyContent="space-between" gap="1rem">
				<Typography component="h2" variant="h5">
					Life plan
				</Typography>
			</Stack>
			<Divider
				sx={{
					marginTop: '1.5rem',
					marginBottom: '2rem',
				}}
			/>
			<Typography component="h4" variant="h6">
				Key Data Points
			</Typography>
			<List sx={{ listStyleType: 'disc', pl: 4 }}>
				<ListItem disablePadding sx={{ display: 'list-item' }}>
					<ListItemText
						primary={
							<Typography>
								<span style={{ fontWeight: 'bold' }}>Retirement Age:</span> Determine your ideal
								retirement age based on your current financial security and freedom goals.
							</Typography>
						}
					/>
				</ListItem>
				<ListItem disablePadding sx={{ display: 'list-item' }}>
					<ListItemText
						primary={
							<Typography>
								<span style={{ fontWeight: 'bold' }}> Spending Goals:</span> Align your spending
								with your priorities, such as health and success, ensuring you maintain a balance
								between living for today and securing tomorrow.
							</Typography>
						}
					/>
				</ListItem>
				<ListItem disablePadding sx={{ display: 'list-item' }}>
					<ListItemText
						primary={
							<Typography>
								<span style={{ fontWeight: 'bold' }}> Savings Goals:</span> Establish a savings
								target that supports your security and freedom, considering your moderately
								conservative risk tolerance.
							</Typography>
						}
					/>
				</ListItem>
				<ListItem disablePadding sx={{ display: 'list-item' }}>
					<ListItemText
						primary={
							<Typography>
								<span style={{ fontWeight: 'bold' }}> Inflows: </span> Evaluate your current income
								sources and explore opportunities to enhance them, ensuring they align with your
								definition of success.
							</Typography>
						}
					/>
				</ListItem>

				<ListItem disablePadding sx={{ display: 'list-item' }}>
					<ListItemText
						primary={
							<Typography>
								<span style={{ fontWeight: 'bold' }}>Legacy Plans: </span> Consider how you want to
								leave a legacy, whether through financial means or personal
							</Typography>
						}
					/>
				</ListItem>
			</List>
		</Box>
	);
};

export default LifePlanPage;
