/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/self-closing-comp */
import { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import './login.scss';

const LoginPage: FunctionComponent = (): ReactElement => {
	const { oktaAuth, authState } = useOktaAuth();

	if (authState?.isAuthenticated) {
		return <Navigate to="/clients" />;
	}

	const login = () => {
		if (!authState) {
			return;
		}

		if (!authState?.isAuthenticated) {
			const originalUri = toRelativeUrl('/clients', window.location.origin);
			oktaAuth.setOriginalUri(originalUri);
			oktaAuth.signInWithRedirect();
		}
	};

	return (
		<div className="auth">
			<div className="auth-container">
				<Card sx={{ minWidth: 444, padding: '24px' }}>
					<CardContent sx={{ padding: 0 }}>
						<Typography variant="h6" component="h1" sx={{ marginBottom: '16px' }}>
							Welcome to Advisor Portal
						</Typography>
						<Typography variant="body1" sx={{ opacity: 0.87 }}>
							To access this application securely, please sign in <br /> using your Okta account
							credentials.
						</Typography>
					</CardContent>
					<Divider sx={{ margin: '24px 0 24px 0' }} />
					<CardActions sx={{ padding: 0 }}>
						<Button
							variant="contained"
							sx={{ backgroundColor: '#027fc7', textTransform: 'none', padding: '10px' }}
							onClick={login}
						>
							<div className="circle">
								<div className="circle-small"></div>
							</div>
							Sign in with Okta
						</Button>
					</CardActions>
				</Card>
			</div>
		</div>
	);
};

export default LoginPage;
