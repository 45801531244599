import { FunctionComponent, ReactElement, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { AUTH_KEY } from '@/shared/utils';

const AuthGuard: FunctionComponent = (): ReactElement => {
	const { authState, oktaAuth } = useOktaAuth();

	useEffect(() => {
		if (oktaAuth && oktaAuth.getAccessToken()) {
			localStorage.setItem(AUTH_KEY, JSON.stringify(authState));
		}
	}, [authState, oktaAuth]);

	if (!oktaAuth.getAccessToken()) {
		return <Navigate to="login" />;
	}

	return <Outlet />;
};

export default AuthGuard;
