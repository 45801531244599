import { getAiOutput, updateAiOutput } from '../ai-output.api';
import { AiOutput, UpdateAiOutputParams } from '../ai-output.types';

export const getSlowBrainAiOutput = async (clientId: string | number): Promise<AiOutput> => {
	return getAiOutput(clientId, 'slow_brain');
};

export const updateSlowBrainAiOutput = async ({
	clientId,
	payload,
}: UpdateAiOutputParams): Promise<void> => {
	return updateAiOutput('slow_brain', clientId, payload);
};
