import { FunctionComponent, ReactElement, useMemo } from 'react';
import { TileQuestionsAnswers } from '@/app/api/flows/answers.types';
import { AccordionList, AccordionItem } from '@/components/ui/accordion';
import useTransformQuestion from './use-transform-question';

interface SelectedTilesListProps {
	tileQuestionsAnswers: TileQuestionsAnswers;
}

const SelectedTilesList: FunctionComponent<SelectedTilesListProps> = ({
	tileQuestionsAnswers,
}): ReactElement => {
	const tileQuestionAnswersAccordionItems: AccordionItem[] = useMemo(() => {
		const { questions } = tileQuestionsAnswers;
		return questions.map((question, index) =>
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useTransformQuestion(question, index === 0)
		);
	}, [tileQuestionsAnswers]);

	return <AccordionList items={tileQuestionAnswersAccordionItems} />;
};

export default SelectedTilesList;
