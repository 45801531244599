import { forwardRef } from 'react';
import {
	MDXEditor,
	MDXEditorMethods,
	BoldItalicUnderlineToggles,
	BlockTypeSelect,
	ListsToggle,
	Separator,
	headingsPlugin,
	listsPlugin,
	quotePlugin,
	thematicBreakPlugin,
	toolbarPlugin,
} from '@mdxeditor/editor';
import './markdown-editor.scss';

interface MarkdownEditorProps {
	markdown: string;
	onChange?: (markdown: string) => void;
}

const MarkdownEditor = forwardRef<MDXEditorMethods, MarkdownEditorProps>(
	({ markdown, onChange }, ref) => (
		<MDXEditor
			ref={ref}
			contentEditableClassName="markdown-editor"
			markdown={markdown}
			onChange={onChange}
			plugins={[
				headingsPlugin(),
				listsPlugin(),
				quotePlugin(),
				thematicBreakPlugin(),
				toolbarPlugin({
					// eslint-disable-next-line react/no-unstable-nested-components
					toolbarContents: () => (
						<>
							<BoldItalicUnderlineToggles />
							<Separator />
							<ListsToggle options={['bullet', 'number']} />
							<Separator />
							<BlockTypeSelect />
						</>
					),
				}),
			]}
		/>
	)
);

MarkdownEditor.displayName = 'Markdown Editor';

export default MarkdownEditor;
