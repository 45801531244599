import { getAiOutput, updateAiOutput } from '../ai-output.api';
import { AiOutput, UpdateAiOutputParams } from '../ai-output.types';

export const getRiskAssessmentAiOutput = async (clientId: string | number): Promise<AiOutput> => {
	return getAiOutput(clientId, 'risk_assessment');
};

export const updateRiskAssessmentAiOutput = async ({
	clientId,
	payload,
}: UpdateAiOutputParams): Promise<void> => {
	return updateAiOutput('risk_assessment', clientId, payload);
};
