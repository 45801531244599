import { useEffect, useRef, useState } from 'react';

interface UseSearchQueryParams {
	query: string;
	delay?: number;
	onChange?: () => void;
}

export const useSearchQuery = ({ query, delay = 200, onChange }: UseSearchQueryParams): string => {
	const [searchQuery, setSearchQuery] = useState('');
	const timerRef = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		timerRef.current = setTimeout(() => setSearchQuery(query), delay);
		return () => clearTimeout(timerRef.current);
	}, [query, delay]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => onChange?.(), [query]);

	return searchQuery;
};
