import { httpClient } from '../http-client';
import { TileQuestionsAnswers } from './answers.types';

export const getTileQuestionsAnswers = async (
	clientId: string | number,
	flow: string
): Promise<TileQuestionsAnswers> => {
	const API_ENDPOINT = `/clients/${clientId}/flows/${flow}/question_and_answers`;
	const { data } = await httpClient.get<TileQuestionsAnswers>(API_ENDPOINT);

	return data;
};
