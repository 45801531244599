import { SxProps } from '@mui/material';

const ADVISOR_PAGE: SxProps = {
	height: '100%',
	minHeight: '100vh',
	padding: '1.5rem',
	marginLeft: '248px',
	backgroundColor: '#f7f9fc',
};

export default {
	advisor_page: ADVISOR_PAGE,
};
