import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { LaunchOutlined as LaunchOutlinedIcon } from '@mui/icons-material';
import { SwitchInput } from '@/components/ui/inputs';

interface SwitchContainerProps {
	text: string;
	link?: string;
	value?: string;
	checked?: boolean;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const SwitchContainer: React.FC<SwitchContainerProps> = ({
	text,
	link,
	value,
	checked,
	handleChange,
}): React.ReactElement => {
	return (
		<Box
			key={text}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: '12px 0',
				borderBottom: '1px solid #e0e0e0',
			}}
		>
			<Box>
				<Typography component="label" htmlFor={text} sx={{ fontSize: '16px' }}>
					{text}
				</Typography>
				{link && (
					<Link
						underline="always"
						href={link}
						sx={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}
					>
						Open in Client Portal
						<LaunchOutlinedIcon sx={{ width: '18px', height: '18px', marginLeft: '4px' }} />
					</Link>
				)}
			</Box>
			<SwitchInput
				aria-label={value}
				value={value}
				id={value}
				checked={checked}
				ariaLabel={text}
				onChange={(event, checked) => handleChange(event, checked)}
			/>
		</Box>
	);
};

export default SwitchContainer;
