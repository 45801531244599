import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { FormDialog } from '@/components/ui/form-dialog';

interface AiOutputLeaveDialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	isSubmitting: boolean;
	content: ReactNode;
	isDisabled?: boolean;
}

const AiOutputLeaveDialog: FunctionComponent<AiOutputLeaveDialogProps> = ({
	open,
	onClose,
	onSubmit,
	isSubmitting,
	content,
	isDisabled = false,
}): ReactElement => {
	return (
		<FormDialog
			title="Save changes?"
			open={open}
			onClose={onClose}
			onSubmit={onSubmit}
			actions={{
				confirm: {
					label: 'Save changes',
					isLoading: isSubmitting,
					disabled: isDisabled,
				},
				cancel: { label: 'Cancel' },
			}}
		>
			<Typography variant="body1">{content}</Typography>
		</FormDialog>
	);
};

export default AiOutputLeaveDialog;
