import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Client } from '@/app/api/clients';
import { Paginated } from '@/app/api/api.types';
import { Column, Pagination, Table } from '@/components/ui/table';
import { DialoguesPortalCell, StatusCell } from './cells';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../clients.constants';

interface ClientsTableProps {
	data?: Paginated<Client>;
	onPageChange: (page: number) => void;
}

const ClientsTable: React.FC<ClientsTableProps> = ({ data, onPageChange }): React.ReactElement => {
	const navigate = useNavigate();

	const {
		content: rows = [],
		totalElements: totalItems = 0,
		pageable: { pageNumber = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE } = {},
	} = data || {};

	const columns: Column<Client>[] = [
		{ name: 'Client', accessors: ['clientName'] },
		{ name: 'Email', accessors: ['clientEmail'] },
		{
			name: 'Dialogues Portal',
			render: ({ clientUrl }) => <DialoguesPortalCell url={clientUrl} />,
		},
		{
			name: 'Status',
			align: 'right',
			render: ({ status }) => <StatusCell status={status} />,
		},
	];
	const onRowClick = ({ id }: Client) => navigate(`/clients/${id}`);
	const pagination: Pagination = {
		count: totalItems,
		page: pageNumber,
		rowsPerPage: pageSize,
		onPageChange,
	};

	return (
		<Table<Client> columns={columns} rows={rows} onRowClick={onRowClick} pagination={pagination} />
	);
};

export default ClientsTable;
