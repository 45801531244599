export enum PortfolioCategory {
	AGGRESSIVE = 'AGGRESSIVE',
	CONSERVATIVE = 'CONSERVATIVE',
	MODERATE = 'MODERATE',
	MODERATELY_AGGRESSIVE = 'MODERATELY AGGRESSIVE',
	MODERATELY_CONSERVATIVE = 'MODERATELY CONSERVATIVE',
}

export interface Portfolio {
	id: number;
	symbol: string;
	name: string;
	category: PortfolioCategory;
}

export interface SelectedPortfolio extends Portfolio {
	clientSelected: boolean;
}

export interface ClientPortfolios {
	clientId: number;
	portfoliosRecommendation: SelectedPortfolio[];
}

export interface UpdateClientPortfoliosParams {
	clientId: string | number;
	payload: ClientPortfolios;
}
