import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ListItem, styled } from '@mui/material';
import { SidebarNavItemProps } from '../sidebar.types';
import SidebarBaseItem from './sidebar-base-item.component';

const SidebarNavLink = styled(NavLink)(() => ({
	textDecoration: 'none',
	color: 'inherit',
	width: '100%',
}));

const SidebarNavItem: React.FC<SidebarNavItemProps & Omit<NavLinkProps, 'to'>> = ({
	title,
	icon,
	path,
	...rest
}): React.ReactElement => (
	<ListItem disablePadding>
		<SidebarNavLink to={path} {...rest}>
			{({ isActive }) => <SidebarBaseItem title={title} icon={icon} selected={isActive} />}
		</SidebarNavLink>
	</ListItem>
);

export default SidebarNavItem;
