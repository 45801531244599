import { createQueryKeys } from '@lukemorales/query-key-factory';
import { PaginationQueryParams, SearchQueryParams } from '@/app/api';
import {
	ClientQueryParams,
	getClient,
	getClientActivityLog,
	getClients,
	getClientSettings,
} from '@/app/api/clients';

export const clientsKeys = createQueryKeys('clients', {
	list: ({ page, size }: PaginationQueryParams) => ({
		queryKey: [{ page, size }],
		queryFn: () => getClients({ page, size }),
		contextQueries: {
			search: ({ search }: SearchQueryParams) => ({
				queryKey: [{ page, size, search }],
				queryFn: () => getClients({ page, size, search }),
			}),
		},
	}),
	single: ({ clientId }: ClientQueryParams) => ({
		queryKey: [clientId],
		queryFn: () => getClient({ clientId }),
		contextQueries: {
			settings: () => ({
				queryKey: [clientId],
				queryFn: () => getClientSettings({ clientId }),
			}),
			activityLog: () => ({
				queryKey: [clientId],
				queryFn: () => getClientActivityLog({ clientId }),
			}),
		},
	}),
});
