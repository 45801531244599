import { CircularProgress, CircularProgressProps } from '@mui/material';
import { Button, ButtonProps } from '../button';
import { DEFAULT_LOADER_POSITION, DEFAULT_LOADER_SIZE } from './loading-button.constants';
import styles from './loading-button.styles';

interface LoadingButtonProps {
	loaderPosition?: 'start' | 'end';
	loaderSize?: CircularProgressProps['size'];
	loading?: boolean;
	ariaLabel?: string;
}

const LoadingButton: React.FC<LoadingButtonProps & ButtonProps> = ({
	loaderPosition = DEFAULT_LOADER_POSITION,
	loaderSize = DEFAULT_LOADER_SIZE,
	loading,
	children,
	disabled,
	ariaLabel,
	...rest
}): React.ReactElement => {
	const loader = (
		<CircularProgress
			sx={loaderPosition === 'start' ? styles.loader_left : styles.loader_right}
			aria-busy={!!loading}
			aria-label={ariaLabel}
			role="progressbar"
			size={loaderSize}
			color="inherit"
		/>
	);

	return (
		<Button {...rest} disabled={loading || disabled}>
			{loading && loaderPosition === 'start' && loader}
			{children}
			{loading && loaderPosition === 'end' && loader}
		</Button>
	);
};

export default LoadingButton;
