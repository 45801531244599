import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import { useCurrentTab } from '@/shared/hooks/use-current-tab';
import { CommonTabsProps } from '../tabs.types';

interface TabsNavProps extends CommonTabsProps {
	variant?: 'standard' | 'fullWidth';
}

const TabsNav: React.FC<TabsNavProps> = ({
	tabs,
	ariaLabel,
	variant = 'standard',
}): React.ReactElement => {
	const routes = tabs.map(({ route }) => route);
	const currentTab = useCurrentTab(routes) || routes[0];

	return (
		<MuiTabs value={currentTab} variant={variant} aria-label={ariaLabel}>
			{tabs.map(({ to, label, route }) => (
				<MuiTab component={NavLink} key={to} label={label} value={route} to={to} />
			))}
		</MuiTabs>
	);
};

export default TabsNav;
