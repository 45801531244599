import { httpClient } from '../../http-client';
import { TableStruct } from './tasks.types';

export const getNextBestActionsTasks = async (clientId: string | number): Promise<TableStruct> => {
	const API_ENDPOINT = `/next-best-actions/${clientId}`;
	const { data } = await httpClient.get<TableStruct>(API_ENDPOINT);

	return data;
};

export const updateNextBestActionsTasks = async ({
	clientId,
	payload,
}: {
	clientId: string | number | undefined;
	payload: TableStruct;
}): Promise<TableStruct> => {
	const { data } = await httpClient.put<TableStruct>(`/next-best-actions/${clientId}`, payload);

	return data;
};
