import { ListItem } from '@mui/material';
import SidebarBaseItem from './sidebar-base-item.component';
import { SidebarActionItemProps } from '../sidebar.types';

const SidebarActionItem: React.FC<SidebarActionItemProps> = ({
	onClick,
	...rest
}): React.ReactElement => (
	<ListItem disablePadding>
		<SidebarBaseItem onClick={onClick} {...rest} />
	</ListItem>
);

export default SidebarActionItem;
