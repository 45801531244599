import { Client } from '@/app/api/clients';
import {
	getClientPortalFlowOutputUrl,
	getClientPortalFlowTileSelectionUrl,
} from '../dialogues.utils';

export const getClientPortalFastBrainTileSelectionUrl = (client: Client): string => {
	return getClientPortalFlowTileSelectionUrl(client, 'fast-brain');
};

export const getClientPortalFastBrainOutputUrl = (client: Client): string => {
	return getClientPortalFlowOutputUrl(client, 'fast-brain');
};
