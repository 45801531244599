import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@/app/queries';
import { clientsKeys } from '@/app/queries/query-keys/clients';
import {
	ClientQueryParams,
	UpdateClientSettingsParams,
	updateClientSettings,
} from '@/app/api/clients';

export const useClient = ({ clientId }: ClientQueryParams) => {
	return useQuery(clientsKeys.single({ clientId }));
};

export const useClientActivityLog = ({ clientId }: ClientQueryParams) => {
	return useQuery({
		...clientsKeys.single({ clientId: String(clientId) })._ctx.activityLog(),
		enabled: Boolean(clientId),
	});
};

export const useClientSettings = ({ clientId }: ClientQueryParams) => {
	return useQuery(clientsKeys.single({ clientId: String(clientId) })._ctx.settings());
};

export const useUpdateClientSettings = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateClientSettingsParams) => {
			return updateClientSettings({ clientId, payload });
		},
		onSuccess: ({ clientId }) => {
			const { queryKey: clientSettingsQueryKey } = queryKeys.clients
				.single({ clientId: String(clientId) })
				._ctx.settings();

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({ queryKey: clientSettingsQueryKey });
		},
	});
};
