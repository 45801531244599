import { Navigate, RouteObject } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { ClientsPage } from '@/clients';
import {
	ClientPage,
	DialoguesPage,
	DialoguesOverviewPage,
	FastBrainPage,
	FastBrainAiOutputPage,
	FastBrainTileSelectionPage,
	SlowBrainPage,
	SlowBrainAiOutputPage,
	SlowBrainTileSelectionPage,
	RiskAssessmentPage,
	RiskAssessmentAiOutputPage,
	RiskAssessmentTileSelectionPage,
	NextBestActionsPage,
	LifePlanPage,
	PortfolioConsiderationsPage,
} from '@/client';
import { LoginPage } from '@/okta/login';
import OktaLayer from '@/okta/okta-layer';
import AuthGuard from './guards/auth-guard';
import NextBestActionsTasksPage from '@/client/next-best-actions/tasks';
import { NextBestActionsAiOutputPage } from '@/client/next-best-actions';

export const routes: RouteObject[] = [
	{
		path: '',
		element: <OktaLayer />,
		children: [
			{
				path: '',
				index: true,
				element: <Navigate to="login" />,
			},
			{
				path: '/login',
				element: <LoginPage />,
			},
			{
				path: '/',
				element: <AuthGuard />,
				children: [
					{
						path: '/clients',
						element: <ClientsPage />,
					},
					{
						path: '/clients/:id',
						element: <ClientPage />,
						children: [
							{
								path: '',
								index: true,
								element: <Navigate to="dialogues" />,
							},
							{
								path: 'dialogues',
								element: <DialoguesPage />,
								children: [
									{
										path: '',
										index: true,
										element: <Navigate to="overview" />,
									},
									{
										path: 'overview',
										element: <DialoguesOverviewPage />,
									},
									{
										path: 'fast-brain',
										element: <FastBrainPage />,
										children: [
											{
												path: '',
												index: true,
												element: <Navigate to="tile-selection" />,
											},
											{
												path: 'tile-selection',
												element: <FastBrainTileSelectionPage />,
											},
											{
												path: 'ai-output',
												element: <FastBrainAiOutputPage />,
											},
										],
									},
									{
										path: 'slow-brain',
										element: <SlowBrainPage />,
										children: [
											{
												path: '',
												index: true,
												element: <Navigate to="tile-selection" />,
											},
											{
												path: 'tile-selection',
												element: <SlowBrainTileSelectionPage />,
											},
											{
												path: 'ai-output',
												element: <SlowBrainAiOutputPage />,
											},
										],
									},
									{
										path: 'risk-assessment-questionnaire',
										element: <RiskAssessmentPage />,
										children: [
											{
												path: '',
												index: true,
												element: <Navigate to="tile-selection" />,
											},
											{
												path: 'tile-selection',
												element: <RiskAssessmentTileSelectionPage />,
											},
											{
												path: 'ai-output',
												element: <RiskAssessmentAiOutputPage />,
											},
										],
									},
								],
							},
							{
								path: 'life-plan',
								element: <LifePlanPage />,
							},
							{
								path: 'portfolio-considerations',
								element: <PortfolioConsiderationsPage />,
							},
							{
								path: 'next-best-actions',
								element: <NextBestActionsPage />,
								children: [
									{
										path: '',
										element: <NextBestActionsTasksPage />,
									},
									{
										path: 'ai-output',
										element: <NextBestActionsAiOutputPage />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'login/callback',
				element: <LoginCallback />,
			},
		],
	},
];
