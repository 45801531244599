import { createContext } from 'react';
import { FlashMessage } from './flash-messages.types';

export interface FlashMessageContextProps {
	open?: boolean;
	message?: FlashMessage;
	onClose: () => void;
	dispatch: (message: FlashMessage) => void;
	remove: () => void;
}

export const FlashMessageContext = createContext<FlashMessageContextProps>({
	onClose: () => {},
	dispatch: () => {},
	remove: () => {},
});
