import { Stack, styled, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

interface TextItemProps {
	title: React.ReactNode | string;
	controls?: React.ReactNode;
	children: React.ReactNode;
}

const TextItemContainer = styled(Stack)(() => ({
	backgroundColor: '#F7F9FC',
	padding: '1rem',
	gap: '0.5rem',
}));

const TextItem: React.FC<TextItemProps> = ({ title, controls, children }): ReactElement => {
	const heading =
		typeof title === 'string' ? (
			<Typography variant="subtitle1" fontWeight="bold">
				{title}
			</Typography>
		) : (
			title
		);

	return (
		<TextItemContainer direction="column" gap="0.5rem">
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				{heading}
				{controls}
			</Stack>
			{children}
		</TextItemContainer>
	);
};

export default TextItem;
