import { FunctionComponent, ReactElement, useState } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import { AdvisorPageLayout } from '@/components/layouts/advisor-page';
import { SearchField } from '@/components/ui/inputs';
import { useSearchQuery } from '@/shared/hooks/use-search-query';
import { ClientsTable } from './clients-table';
import { DEFAULT_PAGE } from './clients.constants';
import { useClients } from './clients.queries';

const ClientsPage: FunctionComponent = (): ReactElement => {
	const [page, setPage] = useState<number>(DEFAULT_PAGE);
	const [searchFilter, setSearchFilter] = useState<string>('');
	const search = useSearchQuery({
		query: searchFilter,
		onChange: () => setPage(DEFAULT_PAGE),
	});

	const { data } = useClients({ page, search });

	return (
		<AdvisorPageLayout title="Clients">
			<Box component={Paper} padding="1.5rem">
				<Stack direction="column" gap="1.5rem">
					<SearchField
						label="Search clients"
						value={searchFilter}
						onChange={(event) => setSearchFilter(event.target.value)}
					/>
					<ClientsTable data={data} onPageChange={(page) => setPage(page)} />
				</Stack>
			</Box>
		</AdvisorPageLayout>
	);
};

export default ClientsPage;
