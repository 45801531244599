import { matchPath, useLocation } from 'react-router-dom';

export const useCurrentTab = (routes: readonly string[]): string | null => {
	const { pathname } = useLocation();

	// Iterate through routes to find a match, including nested routes
	// eslint-disable-next-line no-restricted-syntax
	for (const route of routes) {
		const possibleMatch = matchPath({ path: route, end: false }, pathname);
		if (possibleMatch !== null) {
			return possibleMatch.pattern.path;
		}
	}

	return null;
};
