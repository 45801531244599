import React from 'react';
import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText } from '@mui/material';
import { SidebarBaseItemProps } from '../sidebar.types';

const SidebarBaseItem: React.FC<SidebarBaseItemProps & ListItemButtonProps> = ({
	title,
	icon,
	...rest
}): React.ReactElement => (
	<ListItemButton color="primary.main" {...rest}>
		<ListItemIcon>{icon}</ListItemIcon>
		<ListItemText primary={title} />
	</ListItemButton>
);

export default SidebarBaseItem;
