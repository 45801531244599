import React from 'react';
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';

type SwitchInputProps = Omit<MuiSwitchProps, 'aria-label'> & {
	ariaLabel: string;
};

const SwitchInput: React.FC<SwitchInputProps> = ({ ariaLabel, ...rest }): React.ReactElement => (
	<MuiSwitch aria-label={ariaLabel} {...rest} />
);

export default SwitchInput;
