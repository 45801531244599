import { forwardRef } from 'react';
import { Box, Stack } from '@mui/material';
import { MDXEditorMethods } from '@mdxeditor/editor';
import { Button, LoadingButton } from '@/components/ui/buttons';
import { AiOutputChunk } from '@/shared/types/ai-output';
import { MarkdownEditor } from '@/shared/components/markdown-editor';

interface AiOutputEditorProps {
	chunk: AiOutputChunk | null;
	onCancel: () => void;
	onConfirm: () => void;
	onChange: (markdown: string) => void;
	isSubmitting?: boolean;
	isDisabled?: boolean;
}

const AiOutputEditor = forwardRef<MDXEditorMethods, AiOutputEditorProps>(
	({ chunk, onCancel, onChange, onConfirm, isSubmitting, isDisabled }, ref) => {
		if (!chunk) return null;

		return (
			<Box>
				<MarkdownEditor ref={ref} markdown={chunk.content} key={chunk.index} onChange={onChange} />
				<Stack direction="row" justifyContent="end" gap="0.5rem" mt="1rem" mb="1.5rem">
					<Button variant="text" onClick={onCancel}>
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						loading={isSubmitting}
						onClick={onConfirm}
						disabled={isDisabled}
					>
						Save
					</LoadingButton>
				</Stack>
			</Box>
		);
	}
);

AiOutputEditor.displayName = 'AI Output Editor';

export default AiOutputEditor;
