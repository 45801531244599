import { FunctionComponent, ReactElement } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Stack } from '@mui/material';
import { DialogueContext } from '../dialogues.types';
import DialogueSetup from './overview-setup';
import DialogueActivityLog from './overview-activity-log';

const DialogueOverviewPage: FunctionComponent = (): ReactElement => {
	const { settings, client, activityLog } = useOutletContext<DialogueContext>();

	return (
		<Stack direction="row" marginTop="2rem">
			<DialogueSetup settings={settings} client={client} />
			<DialogueActivityLog client={client} activityLog={activityLog} />
		</Stack>
	);
};

export default DialogueOverviewPage;
