import { FunctionComponent, ReactElement } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DialogueContext } from '../../dialogues.types';
import { SelectedTilesList } from '../../components/tile-selection/selected-tiles-list';
import { useSlowBrainQuestionsAnswers } from '../slow-brain.queries';

const SlowBrainTileSelectionPage: FunctionComponent = (): ReactElement | null => {
	const { client } = useOutletContext<DialogueContext>();
	const { data: tileQuestionsAnswers } = useSlowBrainQuestionsAnswers(client.id);

	return tileQuestionsAnswers ? (
		<SelectedTilesList tileQuestionsAnswers={tileQuestionsAnswers} />
	) : null;
};

export default SlowBrainTileSelectionPage;
