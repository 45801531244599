import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Client } from '@/app/api/clients/clients.types';

interface DialoguesPortalCellProps {
	url: Client['clientUrl'];
}

const DialoguesPortalCell: React.FC<DialoguesPortalCellProps> = ({ url }): React.ReactNode => (
	<Typography
		component={Link}
		to={url}
		variant="body1"
		color="primary"
		onClick={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
	>
		Open in Client portal
	</Typography>
);

export default DialoguesPortalCell;
