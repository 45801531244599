import { Client } from '@/app/api/clients';

const { VITE_CLIENT_PORTAL_URL: CLIENT_PORTAL_URL } = import.meta.env;

export const getClientPortalRiskAssessmentTileSelectionUrl = (client: Client): string => {
	return `${CLIENT_PORTAL_URL}/${client.erpId}/risk-assessment/intro`;
};

export const getClientPortalRiskAssessmentOutputUrl = (client: Client): string => {
	return `${CLIENT_PORTAL_URL}/${client.erpId}/risk-assessment/output`;
};
