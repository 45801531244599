import { FunctionComponent, ReactElement } from 'react';
import { FlashMessageProvider } from '@/app/flash-messages';
import { ThemeProvider } from '@/app/theme';
import { QueryClientProvider } from '@/app/queries';
import { RouterProvider } from '@/app/router';
import './app.scss';

const App: FunctionComponent = (): ReactElement => (
	<ThemeProvider>
		<FlashMessageProvider>
			<QueryClientProvider>
				<RouterProvider />
			</QueryClientProvider>
		</FlashMessageProvider>
	</ThemeProvider>
);

export default App;
