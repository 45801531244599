import {
	Dialog as MuiDialog,
	DialogProps as MuiDialogProps,
	DialogTitle as MuiDialogTitle,
	DialogContent as MuiDialogContent,
	DialogActions as MuiDialogActions,
	Box,
} from '@mui/material';
import { Button, LoadingButton } from '../buttons';
import { DEFAULT_MAX_WIDTH } from './form-dialog.constants';
import { FormDialogProps } from './form-dialog.types';
import styles from './form-dialog.styles';

const FormDialog: React.FC<FormDialogProps & MuiDialogProps> = ({
	open,
	maxWidth = DEFAULT_MAX_WIDTH,
	onClose,
	onSubmit,
	title,
	description,
	actions,
	children,
	...rest
}): React.ReactElement => {
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	};

	return (
		<MuiDialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
			fullWidth
			PaperProps={{
				component: 'form',
				onSubmit: handleSubmit,
			}}
			{...rest}
		>
			<MuiDialogTitle>{title}</MuiDialogTitle>
			<MuiDialogContent>
				{description && <Box>{description}</Box>}
				{children}
			</MuiDialogContent>
			<MuiDialogActions sx={styles.dialog_actions}>
				<Button onClick={onClose}>{actions.cancel.label}</Button>
				<LoadingButton
					variant="contained"
					type="submit"
					loading={actions.confirm.isLoading}
					disabled={actions.confirm.disabled}
				>
					{actions.confirm.label}
				</LoadingButton>
			</MuiDialogActions>
		</MuiDialog>
	);
};

export default FormDialog;
