import React, { useState, ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';

interface NextBestActionsTextFieldProps {
	initialValue: string;
	segment: string;
	item: string;
	index: number;
	onChange: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		segment: string,
		item: string,
		index: number
	) => void;
}

const NextBestActionsTextField: React.FC<NextBestActionsTextFieldProps> = ({
	initialValue,
	segment,
	item,
	index,
	onChange,
}) => {
	const [value, setValue] = useState(initialValue);

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = event.target.value;
		setValue(newValue);
		onChange(event, segment, item, index);
	};

	return (
		<TextField
			id="outlined-basic"
			multiline
			size="small"
			variant="outlined"
			value={value}
			sx={{ width: '20rem' }}
			onChange={handleChange}
		/>
	);
};

export default NextBestActionsTextField;
