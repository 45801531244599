import { AiOutput, UpdateAiOutputParams } from '../ai-output.types';
import { getAiOutput, updateAiOutput } from '../ai-output.api';

export const getFastBrainAiOutput = async (clientId: string | number): Promise<AiOutput> => {
	return getAiOutput(clientId, 'fast_brain');
};

export const updateFastBrainAiOutput = async ({
	clientId,
	payload,
}: UpdateAiOutputParams): Promise<void> => {
	return updateAiOutput('fast_brain', clientId, payload);
};
