import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface DialogueEmptyStateProps {
	title: string;
	text: string;
	callToAction?: React.ReactNode;
}

const DialoguesEmptyState: React.FC<DialogueEmptyStateProps> = ({
	title,
	text,
	callToAction,
}): React.ReactElement => {
	return (
		<Stack direction="column" alignItems="center" gap="2rem">
			<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.75rem' }}>
				<Typography component="h4" variant="subtitle1">
					{title}
				</Typography>
				<Typography
					component="p"
					sx={{ fontSize: '14px', color: '#000000', opacity: '0.6', textAlign: 'center' }}
				>
					{text}
				</Typography>
			</Box>
			{callToAction}
		</Stack>
	);
};

export default DialoguesEmptyState;
