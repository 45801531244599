import { FunctionComponent, ReactElement, useEffect, useRef } from 'react';
import { Snackbar, Alert, AlertTitle, IconButton } from '@mui/material';
import {
	Close as CloseIcon,
	CheckCircleOutlineOutlined as CheckCircleOutlineIcon,
} from '@mui/icons-material';
import { FlashMessage as Message } from './flash-messages.types';
import { DEFAULT_MESSAGE_DURATION, DEFAULT_MESSAGE_POSITION } from './flash-messages.constants';

interface FlashMessageProps {
	message?: Message;
	open: boolean;
	onClose: () => void;
}

const FlashMessage: FunctionComponent<FlashMessageProps> = ({
	message,
	open,
	onClose,
}): ReactElement => {
	const {
		type,
		title,
		content,
		showCloseButton,
		disableSelfClosing,
		position = DEFAULT_MESSAGE_POSITION,
		duration = DEFAULT_MESSAGE_DURATION,
	} = message || {};

	const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		if (disableSelfClosing) return undefined;

		if (timerRef.current) clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => onClose(), duration);

		return () => {
			if (timerRef.current) clearTimeout(timerRef.current);
		};
	}, [disableSelfClosing, duration, onClose]);

	const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
		if (reason !== 'clickaway') onClose();
	};

	const closeAction = (
		<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
			<CloseIcon fontSize="small" />
		</IconButton>
	);

	return (
		<Snackbar open={open} anchorOrigin={position} onClose={handleClose}>
			<Alert
				icon={<CheckCircleOutlineIcon />}
				variant="filled"
				severity={type}
				action={showCloseButton && closeAction}
			>
				{title && <AlertTitle>{title}</AlertTitle>}
				{content}
			</Alert>
		</Snackbar>
	);
};

export default FlashMessage;
