import { SxProps } from '@mui/material';
import { ClientStatus, ClientStatusLabel } from '@/app/api/clients';
import { Label, LabelProps } from '@/components/ui/label';
import { variants, NO_TRANSITION } from './status-label.style';

interface StatusCellProps {
	status: ClientStatus;
}

const StatusLabel: React.FC<StatusCellProps & Omit<LabelProps, 'title'>> = ({
	status,
	size = 'small',
	...rest
}): React.ReactElement => {
	const title = ClientStatusLabel[status];
	const variant = variants[status];

	const style: SxProps = {
		...variant,
		...NO_TRANSITION,
	};

	return <Label size={size} title={title} sx={style} {...rest} />;
};

export default StatusLabel;
