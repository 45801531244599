import { FunctionComponent, ReactElement } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { BreadcrumbsItem } from '@/components/ui/breadcrumbs';
import { TabsNav } from '@/components/ui/tabs';
import { AdvisorPageLayout } from '@/components/layouts/advisor-page';
import { StatusLabel } from '@/shared/components/status-label';
import { useClient } from './client.queries';

const Dialogue: FunctionComponent = (): ReactElement => {
	const { id: clientId } = useParams();
	const { data } = useClient({ clientId });

	const breadcrumbs: BreadcrumbsItem[] = [
		{ title: 'Clients', path: '/clients' },
		{ title: `${data?.clientName}`, path: '' },
	];

	const heading = (
		<Stack direction="column" gap="1rem">
			<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
				<Typography component="h1" variant="h4">
					{data?.clientName}
				</Typography>
				{data?.status && <StatusLabel status={data.status} />}
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
				<Typography component="p" variant="inherit">
					{data?.clientEmail}
				</Typography>
				<Typography component="p" variant="inherit">
					ID: {data?.id}
				</Typography>
			</Box>
		</Stack>
	);

	return (
		<AdvisorPageLayout title={heading} breadcrumbs={breadcrumbs}>
			<Stack direction="column" gap="1.5rem">
				<Box sx={{ borderBottom: 1, borderColor: '#d9dbde' }}>
					<TabsNav
						ariaLabel="Client navigation"
						variant="fullWidth"
						tabs={[
							{
								label: 'Dialogues',
								route: '/clients/:id/dialogues',
								to: 'dialogues',
							},
							{
								label: 'Life plan',
								route: '/clients/:id/life-plan',
								to: 'life-plan',
							},
							{
								label: 'Portfolio considerations',
								route: '/clients/:id/portfolio-considerations',
								to: 'portfolio-considerations',
							},
							{
								label: 'Next best actions',
								route: '/clients/:id/next-best-actions',
								to: 'next-best-actions',
							},
						]}
					/>
				</Box>
				{data && <Outlet context={data} />}
			</Stack>
		</AdvisorPageLayout>
	);
};
export default Dialogue;
