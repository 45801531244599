import { Box } from '@mui/material';
import { FunctionComponent, ReactElement, ReactNode } from 'react';
import './markdown-wrapper.scss';

interface MarkdownWrapperProps {
	children: ReactNode;
}

const MarkdownWrapper: FunctionComponent<MarkdownWrapperProps> = ({ children }): ReactElement => (
	<Box component="div" className="markdown-wrapper">
		{children}
	</Box>
);

export default MarkdownWrapper;
