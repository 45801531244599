import { ClientStatus } from '@/app/api/clients';
import { LabelProps } from '@/components/ui/label';
import { StatusLabel } from '@/shared/components/status-label';

interface StatusCellProps {
	status: ClientStatus;
}

const StatusCell: React.FC<StatusCellProps & Omit<LabelProps, 'title'>> = ({
	status,
	...rest
}): React.ReactElement => {
	return <StatusLabel status={status} {...rest} />;
};

export default StatusCell;
