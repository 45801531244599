import { httpClient } from '../http-client';
import { AiOutput, UpdateAiOutputRequest } from './ai-output.types';

export const getAiOutput = async (clientId: string | number, flow: string): Promise<AiOutput> => {
	const { data } = await httpClient.get<AiOutput>(`/clients/${clientId}/flows/${flow}/ai-output`);

	return data;
};

export const updateAiOutput = async (
	flow: string,
	clientId: string | number,
	payload: UpdateAiOutputRequest
): Promise<void> => {
	const API_ENDPOINT = `/clients/${clientId}/flows/${flow}/ai-output/update`;
	const { data } = await httpClient.post<void>(API_ENDPOINT, payload);

	return data;
};
