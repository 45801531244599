import { SxProps } from '@mui/material';
import { ClientStatus } from '@/app/api/clients/clients.types';

export const NO_TRANSITION: SxProps = {
	transition: 'none',
};

const NOT_STARTED: SxProps = {
	backgroundColor: undefined,
};
const COMPLETED: SxProps = {
	backgroundColor: '#2e7d32',
	color: '#fff',
};
const FAST_BRAIN: SxProps = {
	backgroundColor: '#3f51b5',
	color: '#fff',
};
const SLOW_BRAIN: SxProps = {
	backgroundColor: '#9c27b0',
	color: '#fff',
};
const RISK_ASSESSMENT: SxProps = {
	backgroundColor: '#34abe3',
	color: '#fff',
};
const PORTFOLIO_RECOMMENDATIONS: SxProps = {
	backgroundColor: '#0288d1',
	color: '#fff',
};
const PORTFOLIO_CONSIDERATIONS: SxProps = {
	backgroundColor: '#34abe3',
	color: '#fff',
};
const LIFE_PLAN: SxProps = {
	backgroundColor: '#34abe3',
	color: '#fff',
};
const NEXT_BEST_ACTIONS: SxProps = {
	backgroundColor: '#34abe3',
	color: '#fff',
};
const RTQ: SxProps = {
	backgroundColor: '#34abe3',
	color: '#fff',
};

export const variants = {
	[ClientStatus.NOT_STARTED]: NOT_STARTED,
	[ClientStatus.COMPLETED]: COMPLETED,
	[ClientStatus.FAST_BRAIN]: FAST_BRAIN,
	[ClientStatus.SLOW_BRAIN]: SLOW_BRAIN,
	[ClientStatus.RISK_ASSESSMENT]: RISK_ASSESSMENT,
	[ClientStatus.PORTFOLIO_RECOMMENDATIONS]: PORTFOLIO_RECOMMENDATIONS,
	[ClientStatus.PORTFOLIO_CONSIDERATIONS]: PORTFOLIO_CONSIDERATIONS,
	[ClientStatus.LIFE_PLAN]: LIFE_PLAN,
	[ClientStatus.NEXT_BEST_ACTIONS]: NEXT_BEST_ACTIONS,
	[ClientStatus.RTQ]: RTQ,
};
