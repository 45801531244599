import { SxProps } from '@mui/material';

const TABLE_ROW_ACTION: SxProps = {
	':hover': {
		cursor: 'pointer',
		backgroundColor: '#f5f5f5',
	},
};

export default {
	table_row_action: TABLE_ROW_ACTION,
};
