import { TableRow as MuiTableRow } from '@mui/material';
import { TableCell } from '../table-cell';
import { Column } from '../table.types';
import styles from './table-row.styles';

type TableRowProps<Data> = {
	columns: Column<Data>[];
	data: Data;
	rowAction?: () => void;
};

const TableRow = <Data,>({ columns, data, rowAction }: TableRowProps<Data>): React.ReactElement => {
	return (
		<MuiTableRow component="tr" {...(rowAction ? { sx: styles.table_row_action } : {})}>
			{columns.map((column) => (
				<TableCell key={column.name} column={column} data={data} rowAction={rowAction} />
			))}
		</MuiTableRow>
	);
};

export default TableRow;
