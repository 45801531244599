import { TableCell as MuiTableCell } from '@mui/material';
import { Column } from '../table.types';

type TableCellProps<Data> = {
	column: Column<Data>;
	data: Data;
	rowAction?: () => void;
};

const TableCell = <Data,>({
	column,
	data,
	rowAction,
}: TableCellProps<Data>): React.ReactElement => {
	const defaultCellRender = (column: Column<Data>, data: Data): string => {
		return column?.accessors?.map((accessor) => data[accessor]).join(' ') || '';
	};
	const cellRender = column.render
		? () => column.render(data)
		: () => defaultCellRender(column, data);

	return (
		<MuiTableCell tabIndex={0} align={column.align || 'inherit'} onClick={rowAction}>
			{cellRender()}
		</MuiTableCell>
	);
};

export default TableCell;
