import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	UpdateClientPortfoliosParams,
	updateClientPortfolioConsiderations,
} from '@/app/api/portfolio-considerations';
import { useFlashMessage } from '@/app/flash-messages';
import { queryKeys } from '@/app/queries';

export const useClientPortfolioConsiderations = (clientId: string | number) => {
	return useQuery({
		...queryKeys.portfolio_considerations.clientPortfolios(clientId),
		placeholderData: keepPreviousData,
	});
};

export const usePortfolioConsiderations = (clientId: string | number) => {
	return useQuery({
		...queryKeys.portfolio_considerations.allPortfolios(clientId),
		placeholderData: keepPreviousData,
	});
};

export const useUpdateClientPortfolioConsiderations = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateClientPortfoliosParams) => {
			return updateClientPortfolioConsiderations({ clientId, payload });
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: queryKeys.portfolio_considerations._def,
			});

			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});
		},
	});
};
