import { httpClient } from '../http-client';
import {
	Portfolio,
	ClientPortfolios,
	UpdateClientPortfoliosParams,
} from './portfolio-considerations.types';

export const getClientPortfolioConsiderations = async (clientId: string | number) => {
	const { data } = await httpClient.get<Portfolio[]>(
		`/clients/${clientId}/portfolio-recommendations`
	);

	return data;
};

export const getPortfolioConsiderations = async (clientId: string | number) => {
	const { data } = await httpClient.get<ClientPortfolios>(
		`/clients/${clientId}/client-portfolio-recommendations`
	);

	return data;
};

export const updateClientPortfolioConsiderations = async ({
	clientId,
	payload,
}: UpdateClientPortfoliosParams) => {
	const { data } = await httpClient.put<void>(
		`/clients/${clientId}/portfolio-recommendations/update`,
		payload
	);

	return data;
};
