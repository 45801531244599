import { FunctionComponent, ReactElement } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Box, Paper, Stack } from '@mui/material';
import { TabsNav } from '@/components/ui/tabs';
import { DialogueContext } from '../dialogues/dialogues.types';

const NextBestActionsPage: FunctionComponent = (): ReactElement => {
	const { client, settings, activityLog } = useOutletContext<DialogueContext>();

	return (
		<Paper sx={{ display: 'flex', padding: '1.5rem' }}>
			<Stack direction="column" width="100%">
				<Box component={Paper} elevation={1}>
					<TabsNav
						ariaLabel="Dialogues navigation"
						tabs={[
							{
								label: 'Next best actions tasks',
								route: '/clients/:id/next-best-actions/tasks',
								to: '',
							},
							{
								label: 'AI Output',
								route: '/clients/:id/next-best-actions/ai-output',
								to: 'ai-output',
							},
						]}
					/>
				</Box>

				<Outlet context={{ settings, client, activityLog } satisfies DialogueContext} />
			</Stack>
		</Paper>
	);
};

export default NextBestActionsPage;
