import { Client } from '@/app/api/clients';
import {
	getClientPortalFlowOutputUrl,
	getClientPortalFlowTileSelectionUrl,
} from '../dialogues.utils';

export const getClientPortalSlowBrainTileSelectionUrl = (client: Client): string => {
	return getClientPortalFlowTileSelectionUrl(client, 'slow-brain');
};

export const getClientPortalSlowBrainOutputUrl = (client: Client): string => {
	return getClientPortalFlowOutputUrl(client, 'slow-brain');
};
