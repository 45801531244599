import { FunctionComponent, ReactElement } from 'react';
import { Box, styled } from '@mui/material';
import Markdown from 'react-markdown';
import { MarkdownWrapper } from '@/components/layouts/markdown-wrapper';
import { ButtonProps } from '@/components/ui/buttons/button/button.types';
import { Button } from '@/components/ui/buttons';

const EditSectionButton = styled(Button)<ButtonProps>(({ theme }) => ({
	...theme.typography.body1,
	'&:hover': {
		backgroundColor: 'transparent',
	},
	textDecoration: 'underline',
	textTransform: 'none',
	padding: 0,
	minWidth: 'auto',
	position: 'absolute',
	top: 0,
	right: 0,
}));

const SectionContainer = styled(Box)(() => ({
	padding: '1rem',
	backgroundColor: '#F7F9FC',
}));

const SectionContent = styled(Box)(() => ({
	position: 'relative',
}));

interface AiOutputSectionProps {
	markdown: string;
	onEdit: () => void;
}

const AiOutputSection: FunctionComponent<AiOutputSectionProps> = ({
	markdown,
	onEdit,
}): ReactElement => {
	return (
		<SectionContainer>
			<SectionContent>
				<MarkdownWrapper>
					<Markdown>{markdown}</Markdown>
					<EditSectionButton variant="text" disableRipple disableElevation onClick={onEdit}>
						Edit
					</EditSectionButton>
				</MarkdownWrapper>
			</SectionContent>
		</SectionContainer>
	);
};

export default AiOutputSection;
