import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
	getFastBrainAiOutput,
	getFastBrainTileQuestionsAnswers,
	getSlowBrainAiOutput,
	getSlowBrainTileQuestionsAnswers,
	getRiskAssessmentTileQuestionsAnswers,
	getRiskAssessmentAiOutput,
	getNextBestActionsAiOutput,
	getNextBestActionsTasks,
} from '@/app/api/flows';

export const flowKeys = createQueryKeys('flows', {
	fastBrainAnswers: (clientId: string | number) => ({
		queryKey: ['fast_brain', clientId],
		queryFn: () => getFastBrainTileQuestionsAnswers(clientId),
	}),
	fastBrainAiOutput: (clientId: string | number) => ({
		queryKey: ['fast_brain', clientId],
		queryFn: () => getFastBrainAiOutput(clientId),
	}),
	slowBrainAnswers: (clientId: string | number) => ({
		queryKey: ['slow_brain', clientId],
		queryFn: () => getSlowBrainTileQuestionsAnswers(clientId),
	}),
	slowBrainAiOutput: (clientId: string | number) => ({
		queryKey: ['slow_brain', clientId],
		queryFn: () => getSlowBrainAiOutput(clientId),
	}),
	riskAssessmentAnswers: (clientId: string | number) => ({
		queryKey: ['risk_assessment', clientId],
		queryFn: () => getRiskAssessmentTileQuestionsAnswers(clientId),
	}),
	riskAssessmentAiOutput: (clientId: string | number) => ({
		queryKey: ['risk_assessment', clientId],
		queryFn: () => getRiskAssessmentAiOutput(clientId),
	}),
	nextBestActionsAiOutput: (clientId: string | number) => ({
		queryKey: ['next_best_actions', clientId],
		queryFn: () => getNextBestActionsAiOutput(clientId),
	}),
	nextBestActionsTasks: (clientId: string | number) => ({
		queryKey: ['next_best_actions_table', clientId],
		queryFn: () => getNextBestActionsTasks(clientId),
	}),
});
