import { Fragment, FunctionComponent, ReactElement } from 'react';
import dayjs from 'dayjs';
import { Box, Stack, Typography } from '@mui/material';
import { LaunchOutlined as LaunchOutlinedIcon } from '@mui/icons-material';
import { Client, ClientActivityLog } from '@/app/api/clients';
import { ButtonLink } from '@/components/ui/buttons';
import { DialoguesEmptyState } from '../../components/empty-state';
import './overview-activity-log.scss';

export interface DialogueSetupActivityProps {
	client: Client;
	activityLog: ClientActivityLog[];
}

const DialogueActivityLog: FunctionComponent<DialogueSetupActivityProps> = ({
	client,
	activityLog,
}: DialogueSetupActivityProps): ReactElement => {
	const dateFormat = 'MM/DD/YYYY h:mm A';

	return (
		<Box sx={{ flex: '1 1 100%', paddingLeft: '1.5rem' }}>
			<Stack direction="column" gap="1rem" height="100%">
				<Box sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '1.5rem' }}>
					<Typography component="h3" variant="h6" sx={{ fontWeight: '500' }}>
						Activity Log
					</Typography>
				</Box>
				{!activityLog.length ? (
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						sx={{ flex: '1 1 100%' }}
					>
						<DialoguesEmptyState
							title="No activity yet"
							text="Once the dialogues exercise is initiated by the client, activities will populate in this section."
							callToAction={
								<ButtonLink
									variant="outlined"
									endIcon={<LaunchOutlinedIcon />}
									to={client?.clientUrl}
									target="_blank"
									rel="noopener noreferrer"
								>
									Open client portal
								</ButtonLink>
							}
						/>
					</Box>
				) : (
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
						{activityLog.map((ac) => (
							<Fragment key={ac.flow}>
								{ac.endTime && (
									<Box className="activity-log-item">
										<Box className="activity-log-indicator">
											<Box className="dot" />
										</Box>
										<Box sx={{ marginLeft: '1.5rem', flex: '1 1 100%' }}>
											<Typography component="p">{ac.label} Finished</Typography>
											<Typography component="p" sx={{ fontSize: '0.85rem', opacity: '0.6' }}>
												{dayjs(ac.endTime).format(dateFormat)}
											</Typography>
											<Box display="flex" justifyContent="flex-end">
												<Box className="divider" />
											</Box>
										</Box>
									</Box>
								)}
								<Box className="activity-log-item">
									<Box className="activity-log-indicator">
										<Box className="dot" />
									</Box>
									<Box sx={{ marginLeft: '1.5rem', flex: '1 1 100%' }}>
										<Typography component="p">{ac.label} Started</Typography>
										<Typography component="p" sx={{ fontSize: '0.85rem', opacity: '0.6' }}>
											{dayjs(ac.startTime).format(dateFormat)}
										</Typography>
										<Box display="flex" justifyContent="flex-end">
											<Box className="divider" />
										</Box>
									</Box>
								</Box>
							</Fragment>
						))}
					</Box>
				)}
			</Stack>
		</Box>
	);
};

export default DialogueActivityLog;
