import {
	FunctionComponent,
	ReactElement,
	ReactNode,
	useState,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { Portal } from '@mui/material';
import { FlashMessageContext, FlashMessageContextProps } from './flash-messages.context';
import { FlashMessage } from './flash-messages.types';
import FlashMessageComponent from './flash-messages.component';

export const FlashMessageProvider: FunctionComponent<{ children: ReactNode }> = ({
	children,
}): ReactElement => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState<FlashMessage>();
	const containerRef = useRef<HTMLElement>(document.getElementById('flash-message') as HTMLElement);

	useEffect(() => message && setOpen(true), [message]);

	const onClose = () => setOpen(false);
	const remove = () => setMessage(undefined);
	const dispatch = (message: FlashMessage) => setMessage(message);

	const context: FlashMessageContextProps = useMemo(
		() => ({
			open,
			onClose,
			message,
			dispatch,
			remove,
		}),
		[open, message]
	);

	return (
		<FlashMessageContext.Provider value={context}>
			{children}
			<Portal container={containerRef.current}>
				<FlashMessageComponent open={open} onClose={onClose} message={message} />
			</Portal>
		</FlashMessageContext.Provider>
	);
};
