/* eslint-disable import/no-absolute-path */
import { common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import InterRegular from '/fonts/inter/Inter-Regular.woff2';
import InterItalic from '/fonts/inter/Inter-Italic.woff2';
import InterMedium from '/fonts/inter/Inter-Medium.woff2';
import InterMediumItalic from '/fonts/inter/Inter-MediumItalic.woff2';
import InterSemiBold from '/fonts/inter/Inter-SemiBold.woff2';
import InterSemiBoldItalic from '/fonts/inter/Inter-SemiBoldItalic.woff2';
import InterBold from '/fonts/inter/Inter-Bold.woff2';
import InterBoldItalic from '/fonts/inter/Inter-BoldItalic.woff2';

// Colors
const BLUE_PRIMARY_MAIN = '#34abe3';
const BLUE_PRIMARY_100 = '#34abe3';
const BLUE_PRIMARY_200 = '#0288d1';
const BLUE_PRIMARY_300 = '#3f51b5';

// Fonts
const MAIN_FONT = 'Inter';

const DEFAULT_THEME = createTheme();

const theme = createTheme({
	typography: {
		fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}`,
	},
	palette: {
		primary: {
			main: BLUE_PRIMARY_MAIN,
			100: BLUE_PRIMARY_100,
			200: BLUE_PRIMARY_200,
			300: BLUE_PRIMARY_300,
			contrastText: common.white,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				@font-face: {
					font-family: 'Inter',
					src: url(${InterRegular}) format('woff2'),
					font-weight: 400,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterItalic}) format('woff2'),
					font-weight: 400,
					font-style: italic,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterMedium}) format('woff2'),
					font-weight: 500,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterMediumItalic}) format('woff2'),
					font-weight: 500,
					font-style: italic,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterSemiBold}) format('woff2'),
					font-weight: 600,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterSemiBoldItalic}) format('woff2'),
					font-weight: 600,
					font-style: italic,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterBold}) format('woff2'),
					font-weight: 700,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterBoldItalic}) format('woff2'),
					font-weight: 700,
					font-style: italic,
					font-display: swap,
				}
			`,
		},
	},
});

export default theme;
