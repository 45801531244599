import { TabNavItem } from '@/components/ui/tabs/tabs.types';

export const TILE_SELECTION_TAB: TabNavItem = {
	label: 'Tile Selection',
	route: '/clients/:id/dialogues/slow-brain/tile-selection',
	to: 'tile-selection',
};

export const AI_OUTPUT_TAB: TabNavItem = {
	label: 'AI Output',
	route: '/clients/:id/dialogues/slow-brain/ai-output',
	to: 'ai-output',
};

export const TAB_ROUTES = [TILE_SELECTION_TAB.route, AI_OUTPUT_TAB.route];
