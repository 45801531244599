import {
	Accordion as MuiAccordion,
	AccordionProps as MuiAccordionProps,
	AccordionSummary as MuiAccordionSummary,
	AccordionSummaryProps as MuiAccordionSummaryProps,
	AccordionDetails as MuiAccordionDetails,
	AccordionDetailsProps as MuiAccordionDetailsProps,
	Typography,
	styled,
} from '@mui/material';
import { DEFAULT_EXPAND_ICON } from './accordion.constants';
import { AccordionItem as AccordionItemType } from './accordion.types';

const Accordion = styled(MuiAccordion)<MuiAccordionProps>({
	'&.Mui-expanded': {
		borderTopLeftRadius: '0.25rem',
		borderTopRightRadius: '0.25rem',
	},
});
const AccordionSummary = styled(MuiAccordionSummary)<MuiAccordionSummaryProps>({
	'.MuiAccordionSummary-content': {
		marginTop: '0.75rem',
		marginBottom: '0.25rem',
	},
	'.MuiAccordionSummary-content.Mui-expanded': {
		margin: '0.5rem 0',
	},
});
const AccordionDetails = styled(MuiAccordionDetails)<MuiAccordionDetailsProps>({
	'&.MuiAccordionDetails-root': {
		padding: '1rem',
	},
});

type AccordionItemProps = AccordionItemType;

const AccordionItem: React.FC<AccordionItemProps> = ({
	id,
	title,
	expandIcon = <DEFAULT_EXPAND_ICON />,
	defaultExpanded,
	children,
	...rest
}): React.ReactElement => {
	const { ariaControls = `${id}-content` } = rest || {};

	return (
		<Accordion defaultExpanded={defaultExpanded} elevation={1}>
			<AccordionSummary id={id} aria-controls={ariaControls} expandIcon={expandIcon}>
				<Typography variant="h6">{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default AccordionItem;
