import { FunctionComponent, ReactElement, useState, useEffect, ChangeEvent, Fragment } from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import {
	Box,
	Button,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	SelectChangeEvent,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { TableStruct } from '@/app/api/flows/next-best-actions/tasks.types';
import NextBestActionsSelect from './components/tasks-select';

import {
	useNextBestActionsTasks,
	useUpdateNextBestActionsTasks,
} from '../next-best-actions.queries';

import NextBestActionsTextField from './components/tasks-text-field';

const yesNoOptions = ['Yes', 'No'];

const statusOptions = ['NotStarted', 'In Progress', 'Complete'];

const assigneeOptions = [
	'Lead Advisor',
	'Jr. Advisor',
	'Relationship Manager',
	'Admin',
	'CWAS Rep',
	'Client',
];

const NextBestActionsTasksPage: FunctionComponent = (): ReactElement | null => {
	const [draftTable, setDraftTable] = useState<TableStruct>({});

	const { id } = useParams();
	const { data } = useNextBestActionsTasks(id);

	useEffect(() => {
		if (data) {
			setDraftTable(data);
		}
	}, [data]);

	const { mutate: updateNextBestActionsTasks } = useUpdateNextBestActionsTasks();

	const onSubmit = () => {
		const payload = draftTable;
		if (id) {
			updateNextBestActionsTasks({ clientId: id, payload });
		}
	};

	const handleTextChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		segment: string,
		item: string,
		index: number
	) => {
		const newTbl = { ...draftTable };
		newTbl[segment][item][index] = event.target.value;
		setDraftTable(newTbl);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function debounce<T extends (...args: any[]) => void>(
		func: T,
		delay: number
	): (...args: Parameters<T>) => void {
		let timeoutId: ReturnType<typeof setTimeout>;

		return (...args: Parameters<T>): void => {
			// Clear the previous timeout
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			// Set a new timeout
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	}

	const debouncedOnChange = debounce(handleTextChange, 430);

	const handleSelectChange = (
		event: SelectChangeEvent<number | string>,
		segment: string,
		item: string,
		index: number
	) => {
		const newTbl = { ...draftTable };
		newTbl[segment][item][index] = event.target.value;
		setDraftTable(newTbl);
	};

	const handleDateChange = (
		newValue: Dayjs | null,
		segment: string,
		item: string,
		index: number
	) => {
		const newTbl = { ...draftTable };
		newTbl[segment][item][index] = dayjs(newValue).format('YYYY-MM-DD');
		setDraftTable(newTbl);
	};

	if (data) {
		return (
			<Stack direction="column">
				<Box sx={{ paddingBottom: '1.5rem' }}>
					{Object.keys(draftTable).map((segment, i) => (
						<Fragment key={segment}>
							<Stack direction="row" spacing={2} justifyContent="space-between" useFlexGap>
								<Typography
									component="h3"
									variant="h6"
									sx={{ fontWeight: '500', marginTop: '2rem' }}
								>
									{segment}
								</Typography>

								{i === 0 && (
									<Button
										variant="contained"
										size="small"
										color="primary"
										sx={{ marginY: '2rem' }}
										onClick={onSubmit}
									>
										Save
									</Button>
								)}
							</Stack>
							<TableContainer component={Box}>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow key={`${segment}`}>
											<TableCell sx={{ minWidth: '300px' }}>Financial plan</TableCell>
											<TableCell>Relevant</TableCell>
											<TableCell>Discussed</TableCell>
											<TableCell>Status</TableCell>
											<TableCell>Notes</TableCell>
											<TableCell>Assigned Task</TableCell>
											<TableCell>Assignee</TableCell>
											<TableCell>Task Notes</TableCell>
											<TableCell align="right">Due Date</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{Object.keys(draftTable[segment]).map((item) => (
											<TableRow
												key={`${segment}-${item}`}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row" key={`${segment}-${item}-header`}>
													{[item]}
												</TableCell>
												<TableCell key={`${segment}-${item}-0`}>
													<NextBestActionsSelect
														segment={segment}
														item={item}
														index={0}
														value={draftTable[segment][item][0]}
														options={yesNoOptions}
														handleChange={(event) => handleSelectChange(event, segment, item, 0)}
													/>
												</TableCell>
												<TableCell key={`${segment}-${item}-1`}>
													<NextBestActionsSelect
														segment={segment}
														item={item}
														index={1}
														value={draftTable[segment][item][1]}
														options={yesNoOptions}
														handleChange={(event) => handleSelectChange(event, segment, item, 1)}
													/>
												</TableCell>
												<TableCell key={`${segment}-${item}-2`}>
													<NextBestActionsSelect
														segment={segment}
														item={item}
														index={2}
														value={draftTable[segment][item][2]}
														options={statusOptions}
														handleChange={(event) => handleSelectChange(event, segment, item, 2)}
													/>
												</TableCell>
												<TableCell key={`${segment}-${item}-3`}>
													<NextBestActionsTextField
														initialValue={draftTable[segment][item][3]}
														segment={segment}
														item={item}
														index={3}
														onChange={(event) => debouncedOnChange(event, segment, item, 3)}
													/>
												</TableCell>
												<TableCell key={`${segment}-${item}-4`}>
													<NextBestActionsSelect
														segment={segment}
														item={item}
														index={4}
														value={draftTable[segment][item][4]}
														options={yesNoOptions}
														handleChange={(event) => handleSelectChange(event, segment, item, 4)}
													/>
												</TableCell>

												<TableCell key={`${segment}-${item}-5`}>
													<NextBestActionsSelect
														segment={segment}
														item={item}
														index={5}
														value={draftTable[segment][item][5]}
														options={assigneeOptions}
														handleChange={(event) => handleSelectChange(event, segment, item, 5)}
													/>
												</TableCell>
												<TableCell key={`${segment}-${item}-6`}>
													<NextBestActionsTextField
														initialValue={draftTable[segment][item][6]}
														segment={segment}
														item={item}
														index={6}
														onChange={(event) => debouncedOnChange(event, segment, item, 3)}
													/>
												</TableCell>
												<TableCell key={`${segment}-${item}-7`}>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DatePicker
															value={dayjs(draftTable[segment][item][7])}
															onChange={(newValue) => handleDateChange(newValue, segment, item, 7)}
															sx={{
																cursor: 'pointer',
																width: '10rem',
																border: 'none',
																'& .MuiOutlinedInput-root': {
																	'& fieldset': {
																		border: 'none',
																	},
																},
															}}
														/>
													</LocalizationProvider>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Fragment>
					))}
				</Box>
			</Stack>
		);
	}

	return null;
};

export default NextBestActionsTasksPage;
