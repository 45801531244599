import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';

/**
 * This component should fix the CSS injection order.
 * MaterialUI as CSS-in-JS inject its styles at the bottom of the HTML <head>, which gives Material UI precedence over Tailwind CSS.
 * To reduce the need for the important property, we need to change the CSS injection order.
 */
const GlobalCssPriority: React.FC<{ children: React.ReactNode }> = ({
	children,
}): React.ReactElement => {
	return <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>;
};

export default GlobalCssPriority;
