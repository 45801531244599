import { FunctionComponent, ReactElement } from 'react';
import { Box, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { TextItem } from '@/components/layouts/text-item';
import { DialogueContext } from '../../dialogues.types';
import { useFastBrainQuestionsAnswers } from '../fast-brain.queries';

const FastBrainTileSelectionPage: FunctionComponent = (): ReactElement => {
	const { client } = useOutletContext<DialogueContext>();
	const { data } = useFastBrainQuestionsAnswers(client.id);
	const { tiles = [], questions = [] } = data || {};

	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (!data) return <></>;

	return (
		<Stack direction="column" gap="2rem">
			<List disablePadding>
				{tiles.map((tile) => (
					<Box key={tile}>
						<ListItem disablePadding>
							<Typography variant="h6" py="0.75rem">
								{tile}
							</Typography>
						</ListItem>
						<Divider />
					</Box>
				))}
			</List>
			<Stack direction="column" gap="0.5rem">
				{questions.map(({ questionAndAnswers }) =>
					questionAndAnswers.map(({ question, answer }) => (
						<TextItem title={question} key={question}>
							<Typography variant="body1">{answer}</Typography>
						</TextItem>
					))
				)}
			</Stack>
		</Stack>
	);
};

export default FastBrainTileSelectionPage;
