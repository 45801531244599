import { NavLink, NavLinkProps } from 'react-router-dom';
import { styled, Typography } from '@mui/material';
import { BreadcrumbsItem as BreadcrumbsItemType } from '../breadcrumbs.types';

const BreadcrumbNavLink = styled(NavLink)<NavLinkProps>(({ theme }) => ({
	textDecoration: 'none',
	color: 'inherit',
	fontSize: theme.typography.body1.fontSize,
}));

const BreadcrumbsItem: React.FC<BreadcrumbsItemType> = ({ path, title }): React.ReactElement =>
	path ? (
		<BreadcrumbNavLink to={path}>{title}</BreadcrumbNavLink>
	) : (
		<Typography color="text.primary" variant="body1">
			{title}
		</Typography>
	);

export default BreadcrumbsItem;
