import { Client } from '@/app/api/clients';
import { FlowSegments } from './dialogues.types';

const { VITE_CLIENT_PORTAL_URL: CLIENT_PORTAL_URL } = import.meta.env;

export const getClientPortalFlowOutputUrl = (client: Client, flow: FlowSegments) => {
	return `${CLIENT_PORTAL_URL}/${client.erpId}/${flow}/output`;
};

export const getClientPortalFlowTileSelectionUrl = (client: Client, flow: FlowSegments) => {
	return `${CLIENT_PORTAL_URL}/${client.erpId}/${flow}/tile-selection/intro`;
};
