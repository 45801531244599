import { TablePagination as MuiTablePagination } from '@mui/material';
import { DEFAULT_ROWS_PER_PAGE } from './table-pagination.constants';
import { Pagination } from '../table.types';

type TablePaginationProps = Pagination;

const TablePagination: React.FC<TablePaginationProps> = ({
	count,
	page,
	rowsPerPage = DEFAULT_ROWS_PER_PAGE,
	onPageChange,
}): React.ReactElement => (
	<MuiTablePagination
		component="div"
		count={count}
		rowsPerPage={rowsPerPage}
		rowsPerPageOptions={[rowsPerPage]}
		page={page}
		onPageChange={(event, page) => onPageChange(page, event)}
	/>
);

export default TablePagination;
