import { FunctionComponent, ReactElement, ReactNode, useMemo } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import {
	LaunchOutlined as LaunchIcon,
	ContentCopyOutlined as ContentCopyIcon,
} from '@mui/icons-material';
import { ToggleGroupNav } from '@/components/ui/tabs';
import { Button, ButtonLink } from '@/components/ui/buttons';
import { copyLink } from '@/shared/utils';
import { useCurrentTab } from '@/shared/hooks/use-current-tab';
import { DialogueContext } from '../dialogues.types';
import { DialoguesEmptyState } from '../components/empty-state';
import { TILE_SELECTION_TAB, AI_OUTPUT_TAB, TAB_ROUTES } from './fast-brain.constants';
import {
	getClientPortalFastBrainOutputUrl,
	getClientPortalFastBrainTileSelectionUrl,
} from './fast-brain.utils';

const FastBrainPage: FunctionComponent = (): ReactElement => {
	const { client, settings, activityLog } = useOutletContext<DialogueContext>();
	const currentTab = useCurrentTab(TAB_ROUTES) || TAB_ROUTES[0];

	const hasFastBrainFlowStarted = useMemo(
		() => Boolean(activityLog.find((log) => log.flow === 'fast_brain')),
		[activityLog]
	);
	const isFastBrainFlowDisabled = useMemo(
		() => !settings.flows.find(({ flowName }) => flowName === 'fast_brain'),
		[settings]
	);

	const clientPortalLinks = useMemo(
		() => ({
			[TILE_SELECTION_TAB.route]: getClientPortalFastBrainTileSelectionUrl(client),
			[AI_OUTPUT_TAB.route]: getClientPortalFastBrainOutputUrl(client),
		}),
		[client]
	);
	const clientPortalLink = useMemo(
		() => clientPortalLinks[currentTab],
		[clientPortalLinks, currentTab]
	);

	const emptyContainer = (children: ReactNode) => (
		<Box py="3rem" px="2.5rem">
			{children}
		</Box>
	);

	const fastBrainNotStarted = emptyContainer(
		<DialoguesEmptyState
			title="Fast Brain not started yet"
			text="Once the Fast Brain exercise is initiated by the client, activities will populate in this section."
			callToAction={
				<ButtonLink
					variant="outlined"
					endIcon={<LaunchIcon />}
					to={clientPortalLinks[TILE_SELECTION_TAB.route]}
					target="_blank"
					rel="noopener noreferrer"
				>
					Open Fast Brain
				</ButtonLink>
			}
		/>
	);

	const fastBrainDisabled = emptyContainer(
		<DialoguesEmptyState
			title="Fast Brain is disabled"
			text="If you’d like to enable this section go back to Overview to change settings. "
		/>
	);

	const fastBrainOutlet = (
		<Box>
			<Stack direction="row" justifyContent="space-between" my="2rem">
				<ToggleGroupNav
					ariaLabel="Fast Brain navigation"
					tabs={[TILE_SELECTION_TAB, AI_OUTPUT_TAB]}
				/>
				<Stack direction="row" gap="1rem">
					<ButtonLink
						variant="outlined"
						endIcon={<LaunchIcon />}
						to={clientPortalLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						Open Fast Brain
					</ButtonLink>
					<Button
						variant="outlined"
						endIcon={<ContentCopyIcon />}
						onClick={() => copyLink(clientPortalLink)}
					>
						Copy Link
					</Button>
				</Stack>
			</Stack>
			<Outlet context={{ settings, client, activityLog } satisfies DialogueContext} />
		</Box>
	);

	const renderFastBrainContent = (): ReactNode => {
		if (isFastBrainFlowDisabled) return fastBrainDisabled;
		if (!hasFastBrainFlowStarted) return fastBrainNotStarted;
		return fastBrainOutlet;
	};

	return <Stack direction="column">{renderFastBrainContent()}</Stack>;
};

export default FastBrainPage;
