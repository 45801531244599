import { ClientPortfolios, Portfolio, PortfolioCategory } from './portfolio-considerations.types';

export const clientPortfolioConsiderations: Portfolio[] = [
	{
		id: 1186432,
		symbol: 'P:1186432',
		name: 'Concurrent Balanced Model Portfolio',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1214096,
		symbol: 'P:1214096',
		name: 'Concurrent Equity Income Balanced Strategy EINB',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1241060,
		symbol: 'P:1241060',
		name: 'Concurrent Responsible ESG - Conservative',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1126432,
		symbol: 'P:1186432',
		name: 'Concurrent Balanced Model Portfolio',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1215096,
		symbol: 'P:1214096',
		name: 'Concurrent Equity Income Balanced Strategy EINB',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1241000,
		symbol: 'P:1241060',
		name: 'Concurrent Responsible ESG - Conservative',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 3186432,
		symbol: 'P:1186432',
		name: 'Concurrent Balanced Model Portfolio',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1714096,
		symbol: 'P:1214096',
		name: 'Concurrent Equity Income Balanced Strategy EINB',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1211060,
		symbol: 'P:1241060',
		name: 'Concurrent Responsible ESG - Conservative',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1986432,
		symbol: 'P:1186432',
		name: 'Concurrent Balanced Model Portfolio',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1234096,
		symbol: 'P:1214096',
		name: 'Concurrent Equity Income Balanced Strategy EINB',
		category: PortfolioCategory.MODERATE,
	},
	{
		id: 1141160,
		symbol: 'P:1241060',
		name: 'Concurrent Responsible ESG - Conservative',
		category: PortfolioCategory.MODERATE,
	},
];

export const portfolioConsiderations: ClientPortfolios = {
	clientId: 12345,
	portfoliosRecommendation: [
		{
			id: 1186432,
			symbol: 'P:1186432',
			name: 'Concurrent Balanced Model Portfolio',
			category: PortfolioCategory.MODERATE,
			clientSelected: true,
		},
		{
			id: 1214096,
			symbol: 'P:1214096',
			name: 'Concurrent Equity Income Balanced Strategy EINB',
			category: PortfolioCategory.MODERATE,
			clientSelected: false,
		},
		{
			id: 1241060,
			symbol: 'P:1241060',
			name: 'Concurrent Responsible ESG - Conservative',
			category: PortfolioCategory.MODERATE,
			clientSelected: true,
		},
	],
};
