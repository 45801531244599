import { FunctionComponent, ReactElement } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import oidc from '@/okta/config';

const OktaLayer: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();
	const oktaAuth = new OktaAuth(oidc);

	const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
		navigate(toRelativeUrl(originalUri || '/', window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<Outlet />
		</Security>
	);
};

export default OktaLayer;
