import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UpdateAiOutputParams, updateFastBrainAiOutput } from '@/app/api/flows';
import { useFlashMessage } from '@/app/flash-messages';
import { queryKeys } from '@/app/queries';
import { flowKeys } from '@/app/queries/query-keys/flows';

export const useFastBrainQuestionsAnswers = (clientId?: string | number) => {
	return useQuery({
		...flowKeys.fastBrainAnswers(clientId!),
		enabled: Boolean(clientId),
	});
};

export const useFastBrainAiOutput = (clientId: string | number) => {
	return useQuery({
		...flowKeys.fastBrainAiOutput(clientId),
		placeholderData: keepPreviousData,
	});
};

export const useUpdateFastBrainAiOutput = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateAiOutputParams) => {
			return updateFastBrainAiOutput({ clientId, payload });
		},
		onSuccess: () => {
			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({
				queryKey: queryKeys.flows.fastBrainAiOutput._def,
			});
		},
	});
};
