import { Portfolio, PortfolioCategory } from '@/app/api/portfolio-considerations';

const PORTFOLIO_MANDATE_MAP: Record<PortfolioCategory, string> = {
	AGGRESSIVE: 'Aggressive',
	CONSERVATIVE: 'Conservative',
	MODERATE: 'Moderate',
	'MODERATELY AGGRESSIVE': 'Moderately Aggressive',
	'MODERATELY CONSERVATIVE': 'Moderately Conservative',
};
export const getPortfolioMandateScore = (portfolio: Portfolio) => {
	return PORTFOLIO_MANDATE_MAP[portfolio.category];
};
