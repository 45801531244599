import { TabNavItem } from '@/components/ui/tabs/tabs.types';

export const TILE_SELECTION_TAB: TabNavItem = {
	label: 'Questionnaire',
	route: '/clients/:id/dialogues/risk-assessment-questionnaire/tile-selection',
	to: 'tile-selection',
};

export const AI_OUTPUT_TAB: TabNavItem = {
	label: 'Output',
	route: '/clients/:id/dialogues/risk-assessment-questionnaire/ai-output',
	to: 'ai-output',
};

export const TAB_ROUTES = [TILE_SELECTION_TAB.route, AI_OUTPUT_TAB.route];

export const GENERAL_INVESTMENT_QUESTIONS = 'General Investment Questions';
export const INVESTMENT_BEHAVIOR_QUESTIONS = 'Investment Behavior Questions';
export const EMOTIONAL_RISK_TOLERANCE = 'Emotional Risk Tolerance';
