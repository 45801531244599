import * as React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const cache = createCache({
	key: 'css',
	prepend: true,
});

/**
 * Setup the caching mechanism in order that overrides the one coming from MUI React
 */
const PlainCssPriority: React.FC<{ children: React.ReactNode }> = ({
	children,
}): React.ReactElement => {
	return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export default PlainCssPriority;
