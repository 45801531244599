import { AccordionItem as AccordionItemType } from './accordion.types';
import AccordionItem from './accordion-item.component';

interface AccordionListProps {
	items: AccordionItemType[];
}

const AccordionList: React.FC<AccordionListProps> = ({ items }): React.ReactElement => (
	<>
		{items.map((item) => (
			<AccordionItem key={item.id} {...item} />
		))}
	</>
);

export default AccordionList;
