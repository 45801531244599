import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
	getClientPortfolioConsiderations,
	getPortfolioConsiderations,
} from '@/app/api/portfolio-considerations';

export const portfolioConsiderationsKeys = createQueryKeys('portfolio_considerations', {
	clientPortfolios: (clientId: string | number) => ({
		queryKey: ['clients', clientId],
		queryFn: () => getClientPortfolioConsiderations(clientId),
	}),
	allPortfolios: (clientId: string | number) => ({
		queryKey: ['all', clientId],
		queryFn: () => getPortfolioConsiderations(clientId),
	}),
});
