import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UpdateAiOutputParams, updateSlowBrainAiOutput } from '@/app/api/flows';
import { useFlashMessage } from '@/app/flash-messages';
import { queryKeys } from '@/app/queries';
import { flowKeys } from '@/app/queries/query-keys/flows';

export const useSlowBrainQuestionsAnswers = (clientId?: string | number) => {
	return useQuery({
		...flowKeys.slowBrainAnswers(clientId!),
		enabled: Boolean(clientId),
	});
};

export const useSlowBrainAiOutput = (clientId: string | number) => {
	return useQuery({
		...flowKeys.slowBrainAiOutput(clientId),
		placeholderData: keepPreviousData,
	});
};

export const useUpdateSlowBrainAiOutput = () => {
	const queryClient = useQueryClient();
	const { dispatch } = useFlashMessage();

	return useMutation({
		mutationFn: ({ clientId, payload }: UpdateAiOutputParams) => {
			return updateSlowBrainAiOutput({ clientId, payload });
		},
		onSuccess: () => {
			dispatch({
				type: 'success',
				content: 'Changes successfully saved!',
			});

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			queryClient.invalidateQueries({
				queryKey: queryKeys.flows.slowBrainAiOutput._def,
			});
		},
	});
};
