import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';

type LabelProps = Omit<MuiChipProps, 'label'> & { title: string };

const Label: React.FC<LabelProps> = ({
	title,
	size = 'medium',
	variant = 'filled',
	...rest
}): React.ReactElement => <MuiChip label={title} size={size} variant={variant} {...rest} />;

export default Label;
