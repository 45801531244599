import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
	ClientPortfolios,
	SelectedPortfolio,
	UpdateClientPortfoliosParams,
} from '@/app/api/portfolio-considerations';
import { FormDialog } from '@/components/ui/form-dialog';
import { Column, Pagination, Table } from '@/components/ui/table';
import { DEFAULT_PAGE_SIZE } from './portfolios-dialog.constants';
import { useUpdateClientPortfolioConsiderations } from '../portfolio-considerations.queries';
import { getPortfolioMandateScore } from '../portfolio-considerations.utils';

interface PortfoliosUpdateDialogProps {
	dialog: {
		open: boolean;
		onClose: () => void;
		onUpdate?: () => void;
	};
	table: {
		data?: ClientPortfolios;
		page: number;
		pageSize?: number;
		onPageChange: (page: number) => void;
	};
}

const PortfoliosDialog: FunctionComponent<PortfoliosUpdateDialogProps> = ({
	dialog: { open, onClose, onUpdate },
	table: { data, page, pageSize = DEFAULT_PAGE_SIZE, onPageChange },
}): ReactElement => {
	const portfolios = useMemo(() => data?.portfoliosRecommendation || [], [data]);
	const [selectedPortfolios, setSelectedPortfolios] = useState<SelectedPortfolio[]>([]);

	useEffect(() => {
		if (open) {
			const selectedPortfolios = portfolios.filter((p) => p.clientSelected);
			setSelectedPortfolios(selectedPortfolios);
		}
	}, [portfolios, open]);

	const currentPageItems = useMemo(() => {
		const start = page * pageSize;
		const end = start + pageSize;
		return portfolios.slice(start, end);
	}, [portfolios, page, pageSize]);

	const isSelectedAll = useMemo(() => {
		if (portfolios.length !== selectedPortfolios.length) return false;

		const sortedPortfolios = [...portfolios].sort((p1, p2) => p1.id - p2.id);
		const sortedSelectedPortfolios = [...selectedPortfolios].sort((p1, p2) => p1.id - p2.id);

		return sortedPortfolios.every((value, index) => value === sortedSelectedPortfolios[index]);
	}, [portfolios, selectedPortfolios]);

	const isSelectedSome = useMemo(
		() => !!selectedPortfolios.length && !isSelectedAll,
		[selectedPortfolios, isSelectedAll]
	);

	const togglePortfolioSelect = (portfolio: SelectedPortfolio) => {
		const isSelected = selectedPortfolios.find((p) => p.id === portfolio.id);
		const portfolios = isSelected
			? selectedPortfolios.filter((p) => p.id !== portfolio.id)
			: [...selectedPortfolios, portfolio];

		setSelectedPortfolios(portfolios);
	};

	const toggleSelectAll = () => {
		const selectedPortfolios = !isSelectedAll ? portfolios : [];
		setSelectedPortfolios(selectedPortfolios);
	};

	const { mutate, isPending: isUpdating } = useUpdateClientPortfolioConsiderations();

	const onSubmit = () => {
		const { clientId } = data || {};
		if (!clientId) return;

		const portfoliosRecommendation = portfolios.map((portfolio) => ({
			...portfolio,
			clientSelected: !!selectedPortfolios.find(
				(selectedPortfolio) => portfolio.id === selectedPortfolio.id
			),
		}));
		const payload: ClientPortfolios = {
			clientId,
			portfoliosRecommendation,
		};
		const params: UpdateClientPortfoliosParams = {
			clientId,
			payload,
		};

		mutate(params, {
			onSuccess: () => onUpdate?.(),
		});
	};

	const columns: Column<SelectedPortfolio>[] = [
		{
			name: 'Name',
			renderHead: () => (
				<FormControlLabel
					label="Name"
					control={
						<Checkbox
							checked={isSelectedAll}
							indeterminate={isSelectedSome}
							onChange={toggleSelectAll}
						/>
					}
				/>
			),
			render: (portfolio: SelectedPortfolio) => (
				<FormControlLabel
					label={portfolio.name}
					control={
						<Checkbox
							checked={!!selectedPortfolios.find((p) => p.id === portfolio.id)}
							onChange={() => togglePortfolioSelect(portfolio)}
						/>
					}
				/>
			),
		},
		{
			name: 'P Number',
			accessors: ['symbol'],
		},
		{
			name: 'Mandate Score',
			render: (portfolio: SelectedPortfolio) => getPortfolioMandateScore(portfolio),
		},
	];

	const pagination: Pagination = {
		count: portfolios.length,
		page,
		rowsPerPage: pageSize,
		onPageChange,
	};

	return (
		<FormDialog
			title="Portfolio Considerations"
			maxWidth="md"
			closeAfterTransition={false}
			open={open}
			onClose={onClose}
			onSubmit={onSubmit}
			actions={{
				confirm: { label: 'Save', isLoading: isUpdating },
				cancel: { label: 'Cancel ' },
			}}
		>
			<Table<SelectedPortfolio> columns={columns} rows={currentPageItems} pagination={pagination} />
		</FormDialog>
	);
};

export default PortfoliosDialog;
