import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@/app';

/**
 * Perform accessibility test using `axe-core` (https://github.com/dequelabs/axe-core) testing library and
 * output the results in the browser console assigning a severity level to each violation.
 */
(async () => {
	if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
		const axe = await import('@axe-core/react');
		const ReactDOM = await import('react-dom');

		await axe.default(React, ReactDOM, 1000);
	}
})().catch((error) => console.error(error));

createRoot(document.getElementById('root')!).render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);
