/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { OktaAuthOptions } from '@okta/okta-auth-js';

const { VITE_APP_CLIENT_ID, VITE_APP_ISSUER } = import.meta.env;

const CLIENT_ID = VITE_APP_CLIENT_ID ?? '{clientId}';
const ISSUER = VITE_APP_ISSUER ?? 'https://{yourOktaDomain}/oauth2/default';
const REDIRECT_URI = `${window.location.origin}/login/callback`;

const oidc = {
	clientId: CLIENT_ID,
	issuer: ISSUER,
	redirectUri: REDIRECT_URI,
	scopes: ['openid', 'profile', 'email', 'offline_access'],
	pkce: true,
	responseType: ['id_token', 'token', 'refresh_token'],
} as OktaAuthOptions;

export default oidc;
